/**
 *
 * Custom function
 *
 */

(function($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() {
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function() {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function() {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function() {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function() {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function() {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if (!$.cookie("openmodal")) {
            $('#onloadmodal').modal();
            $.cookie("openmodal", 'no', {
                path: '/'
            });
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function() {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function(e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function() {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function() {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="fa fa-angle-right angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function(event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function() {
                $('.angle-scroll-left').click(function(event) {
                    $(this).animate({
                        scrollLeft: '+=30'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function() {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }


    /**
     * Funzione per l'attivazione del menu responsive
     */

    function responsiveMenu() {
        $('#menu-menu-alto').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
            });
        });

        $('#menu-menu-alto').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                $(this).next().toggleClass('open');
                $('ul.sub-menu').not($(this).next()).removeClass('open');
            });
        });
    }

    function scrollAnimations(desktopScrollTop) {

        var header = $('#js-header');
        var offset = 77;

        //if ($('body').is('.mobile, .tablet')) {
        if ($('body').is('.mobile')) {
            var headerH = $('#js-header').outerHeight(); // Height compreso di margini e bordi
            console.log('height', headerH - offset);
            header.css('height', headerH - 8 );

            if(!$('body').hasClass('home')) {
                header.addClass("scroll");
            }
        }

        $(window).scroll(function() {
            var scrolltop = $(window).scrollTop();

            //if ($('body').is('.mobile, .tablet')) {
            if ($('body').is('.mobile')) {
                if (scrolltop >= headerH - offset) {
                    header.addClass("scroll");
                } else {
                    header.removeClass("scroll");
                }
            } else {
                if (scrolltop >= desktopScrollTop) {
                    header.addClass("scroll");

                    if ($('#height-placeholder').length <= 0) {
                        $('#page').prepend('<div id="height-placeholder"></div>');
                        $('#height-placeholder').css('height', headerH);
                    }

                } else {
                    header.removeClass("scroll");

                    if ($('#height-placeholder').length >= 0) {
                        $('#height-placeholder').remove();
                    }
                }
            }
        });
    }

    /*
     *  Funzioni per il countdown delle offerte
     */
    function printCountdown(elem) {

        var deadline = elem.data('deadline').split(',');
        var lang = elem.data('lang');

        var austDay = new Date(
            deadline[0], deadline[1], deadline[2], deadline[3], deadline[4], deadline[5]
        );
        austDay.setSeconds(austDay.getSeconds() + 2);

        elem.find('.countdown').countdown(
            $.extend({
                    until: austDay,
                    compact: true,
                    layout: '{dn} {dl}, {hn} {hl}'
                        //layout: '{dn} {dl}, {hn} {hl}, {mn} {ml}'
                },
                $.countdown.regionalOptions[lang]
            )
        );
    }

    function offerCountdown() {
        $('.countdownWrapper').each(function() {
            var deadline_days = $(this).attr('data-deadline-days');
            var countdown = $(this);

            if (typeof deadline_days !== typeof undefined && deadline_days !== false) {
                $.ajax({
                    url: site_url + '/timestamp.php',
                    type: 'POST',
                    // data: {
                    // 	'action' : 'get_server_time'
                    // },
                    success: function(curTimeStamp) {
                        if ((countdown.data('deadline-ts') - curTimeStamp) < ((deadline_days - 1) * 24 * 60 * 60)) {
                            printCountdown(countdown);
                            countdown.removeClass('hidden');
                        }
                    },
                    error: function(error) {
                        console.log(error);
                    }
                });
            } else {
                printCountdown(countdown);
                countdown.removeClass('hidden');
            }
        });
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function(index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        var myImage = {};

        galleryItems.each(function(index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };

            pswpItems.push(myImage);
        });


        galleryItems.each(function(index) {
            $(this).click(function(evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


 	/**
	 * Funzione per lo scroll morbido tra ancore interne
	 */

      function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					if (target.attr('data-paddingTopScroll')) {
						var adjust = target.attr('data-paddingTopScroll');
					} else {
						var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
					}

					$('html, body').animate({
						scrollTop: target.offset().top - adjust
					}, 1000);

					return false;
				}
			}
		});
	}

    function startOwl( selector ) {
        $(selector).each( function( args ) {
            var owl = $(this);
            var params = {
                navigation: owl.data('owl-navigation'),
                slideSpeed: owl.data('owl-slide-speed'),
                autoPlay: owl.data('owl-autoplay'),
                pagination: owl.data('owl-pagination'),
                paginationSpeed: owl.data('owl-pagination-speed'),
                transitionStyle: owl.data('owl-transition'),
                scrollPerPage: owl.data('owl-scroll-per-page'),
                autoHeight: false,
                loop: owl.data('owl-loop'),
                addClassActive: owl.data('owl-add-class-active'),
                navigationText: ["<i class='icon-edt-arrow-left-2'></i>","<i class='icon-edt-arrow-right-2'></i>"],
            };

            if (owl.data('owl-single-item')) {
                params['singleItem'] = true;
            } else {
                params['items'] = owl.data('owl-items-xl'); //numero item da 1680 in su
                params['itemsDesktop'] = [1679, owl.data('owl-items-lg')]; //numero item tra 1679 e 1200
                params['itemsDesktopSmall'] = [1199, owl.data('owl-items-md')]; //numero item tra 1199 e 992 (tablet landscape)
                params['itemsTablet'] = [991, owl.data('owl-items-sm')]; //numero item tra 991 e 768 (tablet portrait)
                params['itemsMobile'] = [767, owl.data('owl-items-xs')]; //numero item da 767 in giu (cell)
            }

            if(owl.hasClass('blo')) {
                params['afterAction'] = callback;
            }

            if (owl.hasClass('sync')) {
                params['afterAction'] = syncOwl
            }

            owl.owlCarousel(params);

            if (owl.data('owl-prev')) {
                $(owl.data('owl-prev')).click(function() {
                    owl.trigger('owl.prev');
                });
            }
            if (owl.data('owl-next')) {
                $(owl.data('owl-next')).click(function() {
                    owl.trigger('owl.next');
                });
            }
        });
    }

    // function checkLastSlide(event) {
    //     console.log('aaaaa');
    //     if (event.item) {
    //         var current = event.item.index;
    //         var total = event.item.count;

    //         // Verifica se siamo sull'ultima slide
    //         if (current === total - 1) {
    //             setTimeout(function () {
    //                 $('.owl-carousel').trigger('to.owl.carousel', [0, 0, true]);
    //             }, 0);
    //         }
    //     }
    // }

    function callback(elem) {
        var currentItem = this.currentItem;

        console.log(currentItem);
        
        // Rimuovi la classe 'active' da tutti gli elementi del carousel
        $('.owl-item').removeClass('active');

        // Aggiungi la classe 'active' all'elemento corrente
        $('.owl-item').eq(currentItem).addClass('active');
    }

    function syncOwl(elem) {
        var current = this.currentItem;
        var syncID = elem.data('owl-sync');
        var owlToSync = $('.owl-carousel[data-owl-sync="' + syncID + '"]');
        $(owlToSync).trigger('owl.goTo', current);
    }

    /*
     * Funzione per utilizzare MixItUp da select
     */

    function filterElements() {

        var filterSelect = '.select-filters',
            container = '.mix-filter-support';

        if ($(container).length) {
            var mixer = mixitup(container, {
                selectors: {
                    control: '[data-mixitup-control]'
                }
            });

            $(filterSelect).on('change', function() {
                console.log("filterSelect change");
                mixer.filter(this.value);
            });
        }
    }

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function isInArray(value, array) {
        return array.indexOf(value) > -1;
    }

    function assignLikes() {
        var selectedStructures = document.getElementById('selectedStructures');
        var countStr = document.getElementById('countStr');
        var store = JSON.parse(localStorage.getItem("like") || '[]');

        if (selectedStructures != null) {
            for (var i = 0; i < store.length; i++) {
                if (document.querySelectorAll('.heart[data-strid="' + store[i] + '"]')[0] != null) {
                    if (document.querySelectorAll('.heart[data-strid="' + store[i] + '"]')[0].classList.contains('fa-heart-o')) {
                        document.querySelectorAll('.heart[data-strid="' + store[i] + '"]')[0].className += ' fa-heart';
                        document.querySelectorAll('.heart[data-strid="' + store[i] + '"]')[0].classList.remove('fa-heart-o');
                    }
                }
            }

            if (store.length == 0) {
                selectedStructures.style.display = 'none';
            } else {
                selectedStructures.style.display = 'flex';
            }

            countStr.innerHTML = store.length;
        }
    }

    function like(e) {
        if (!e.target.classList.contains('mylink'))
            return false;

        var selectedStructures = document.getElementById('selectedStructures');
        var countStr = document.getElementById('countStr');
        var store = JSON.parse(localStorage.getItem("like") || '[]');
        if (isInArray(e.target.getAttribute("data-strid"), store)) {
            for (var i = 0; i < store.length; i++) {
                if (store[i] === e.target.getAttribute("data-strid")) {
                    store.splice(i, 1);
                }
            }
        } else {
            store.push(e.target.getAttribute("data-strid"));
        }

        localStorage.setItem("like", JSON.stringify(store));
        //console.log('store', store);
        //toggle selected class
        if (e.target.classList.contains('fa-heart-o')) {
            e.target.className += ' fa-heart';
            e.target.classList.remove('fa-heart-o');
        } else {
            e.target.className += ' fa-heart-o';
            e.target.classList.remove('fa-heart');
        }
        if (store.length == 0) {
            selectedStructures.style.display = 'none';
        } else {
            selectedStructures.style.display = 'flex';
        }
        countStr.innerHTML = store.length;
    }


    document.addEventListener('click', like, false);

    $j(document).ready(function() {
        assignLikes();
        allClick();
        responsiveTable();
        addImageSizeForPhotoSwiper();
        offerCountdown();
        iframeModalOpen();
        responsiveMenu();
        scrollAnimations(200);
        startOwl('.owl-carousel');
        filterElements();
        smoothScroll();

        setTimeout(function(){
            $('body').addClass('loaded');
            // $('h1').css('color','#222222');
        }, 2000);
        
        //triggered when modal is about to be shown
        $('#myModal').on('show.bs.modal', function(e) {
            // console.log(e.target);
            if ($('body').hasClass('single-struttura')) {
                var value = $('input[name="input_53"]').val();
                console.log(value);
                $(e.currentTarget).find('input[name="input_62"]').val(value);
            }
            if ($(e.target).hasClass('search')) {
                var store = JSON.parse(localStorage.getItem("like") || '[]');
                $(e.currentTarget).find('input[name="input_62"]').val(store);
            }

        });

        $('.gform_wrapper form').each(function() {

            /*
             *	Implementazione per il tracciamento della conversione sui form che hanno uno specifico nome
             */

            $(this).checkEditaForm({ bgerror: '#ffa5a5' });
        });

        $('.checkNewsletter').each(function() {
            $(this).checkEditaForm({ bgerror: '#ffa5a5' });
        });

        if ($('.show-phone').length != 0) {
            $('.show-phone').click(function(e) {
                e.preventDefault();
                $('.show-phone').hide();
                $('.hotel-phone-number').show();
                ga('send', 'event', 'Link', 'Click', 'Mostra Telefono');
            });
        }

        $('#js-fixed-menu').find('.mobi-buttons .calendar').click(function() {
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 500, function() {
                $('#collapseForm').collapse('show');
            });

        });

        console.log('<?php echo get_template_directory(); ?>archive-case_vacanze.php');


        $('#box-casevacanza article').each( function() {
            $(this).on('click', function() {
                var immobile_id = $(this).attr("data-id-immobile");
                sessionStorage.setItem('ID_immobile', immobile_id);
            });
        });

        if($('body').hasClass('with-slide') && $(window).width() >= 1187) {
            var slideshowHeight = $('.header-slideshow').outerHeight();
            var bookingCta = $('.cta-booking');

            $(window).scroll(function() {
                var scrolltop = $(window).scrollTop();
                var headerHeight = $('#js-header').outerHeight();
                var trueHeight = slideshowHeight - headerHeight;
                
                if(scrolltop >= trueHeight) {
                    bookingCta.show();
                }
                else {
                    bookingCta.hide();
                }
            });
        }

        setTimeout(function() { 
            $extBooking = $('.form-kross-booking');

            if($extBooking) {
                $extBooking.addClass('edtrack-booking-click');
            }
        }, 2000);

        
    });

    $j(window).load(function() {

        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */
        if (!$('body').hasClass('mobile')) {
            $.getScript('https://www.google.com/jsapi', function() {
                google.load('maps', '3', {
                    other_params: 'sensor=true&language=it&key=' + mapskey,
                    callback: function() {
                        initializeGmap();
                    }
                });
            });
        }

        //openModal();
        initPhotoSwiper(".entry-content .gallery-item");
        initPhotoSwiper(".entry-content .single-image");


        $('.js-nome-equalHeights').equalHeights();
        $('.js-boxOfferta-equalHeights').equalHeights();
        
        if (!$('body').hasClass('mobile')) {
            $('.js-boxDestinazione-equalHeights').equalHeights();
            $('#offerts .boxoff .bottomtext .title').equalHeights();
            $('#offerts .boxoff .bottomtext .text').equalHeights();
            // $('body.single-offerte .info-container>.item').equalHeights();
        } 


        //if($(window).width() > 1024 && $(window).width() <= 1680) {
        if($(window).width() > 1024) {
            $('.sameHeight').equalHeights();
        }



    });

    $j(window).resize(function() {

        $('.js-boxDestinazione-equalHeights').equalHeights();
        $('.js-nome-equalHeights').equalHeights();
        $('.js-boxOfferta-equalHeights').equalHeights();
        //if (!$('body').is('.mobile, .tablet')) $('.js-colonna1-equalHeights').equalHeights(); 
        // if (!$('body').is('.mobile')) $('.js-colonna1-equalHeights').equalHeights(); 
    });


})(jQuery);

jQuery(function($) {
    $(document).on('gform_post_render', function(e, form_id) {
        $('#gform_ajax_frame_' + form_id).attr('src', 'about:blank');
    });

    $('#filter').submit(function() {

        var countChecked = $('#filter :checked').length;
        if (countChecked > 0) {
            var filter = $('#filter');
            $.ajax({
                url: filter.attr('action'),
                data: filter.serialize(), // form data
                type: filter.attr('method'), // POST
                beforeSend: function(xhr) {
                    filter.find('button').text('carico strutture...'); // changing the button label
                    // $('#load').show();
                },
                success: function(data) {
                    alert("#filter submit")
                    window.scrollTo(0, 0);
                    filter.find('button').text('Applica Filtri'); // changing the button label back
                    $('#structureList').find('.structureItemList').hide();

                    
                    if( JSON.parse(data).length > 0 ){   
                        $.each(JSON.parse(data), function(index, value) {
                            $('#structureList').find(`[data-strid='${value}']`).show();
                        });
                    }else{
                        console.log('no',data.length);
                    }
                }
            });
        } else {
            $('#structureList').find('.structureItemList').show();
        }

        return false;
    });
});

jQuery('.owl-carousello').owlCarousel({
    navigation: false, // Show next and prev buttons
    slideSpeed: 300,
    paginationSpeed: 400,
    singleItem: true,
    loop: true,
})